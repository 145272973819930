@import "../scss/icons.scss";
@import "../scss/config/minimal/bootstrap.scss";
@import "../scss/config/minimal/app.scss";
@import "../scss/config/minimal/custom.scss";  
@import"./shepherd.css";
@import "./mermaid.min.css";

//RTL 
// @import "../scss/rtl/components-rtl";
// @import "../scss/rtl/layouts-rtl";
// @import "../scss/rtl/plugins-rtl";
// @import "../scss/rtl/pages-rtl";


//Rigthsidebar offcanvas
.offcanvas-header-dark {
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

body {
  * {
    outline: none;
  }
}
.noUi-horizontal .noUi-handle {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
  right: -10px !important;
  top: -7px !important;
}
.noUi-connect {
  background: #25a0e2 !important;
  .noUi-horizontal {
    height: 2px !important;
  }
}
.noUi-handle {
  background: #25a0e2 !important;
  border: 2px solid var(--vz-card-bg) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.noUi-target {
  background: #fafafa !important;
  border-radius: 4px !important;
  border: 1px solid #d3d3d3 !important;
  background-color: var(--vz-light) !important;
  border-color: var(--vz-light) !important;
  box-shadow: none !important;
  height: 4px !important;
}
.dropzone .dz-message {
  text-align: center;
}

// //Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}
.fc-event {
  border: none !important;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}
.flatpickr-calendar.open {
  z-index: 1090 !important;
}
.upcoming-scheduled .flatpickr-input[readonly] {
  display: none;
}
.ql-container.ql-snow {
  height: 250px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #25a0e2 !important;
}

// .Toastify__toast{
//   min-height: 43px !important;
//   max-height: 43px !important;
// }

@each $color, $value in $theme-colors {
  .simplebar-track-#{$color} {
    .simplebar-scrollbar:before{
      background: $value !important; 
    }
  }   
}

//authentication
#tsparticles{
  canvas{
    position: absolute !important;
  }
}

// chat
.chat-conversation .chat-list .message-box-drop{
  .nav-btn{
      font-size: 18px;
      padding: 4px;
      color: $gray-600;
  }
}

.chatwidgets {
  height: 250px !important;
}

[data-layout="horizontal"] .chat-conversation{
  &.chatwidgets {
    height: 250px !important;
  }
}

// landing page
.process-card {
  .process-arrow-img {
      left: auto;
      right: 75%;
      transform: scaleX(-1);
  }
}
.icon-effect {
  &::before {
      left: auto;
      right: 0;
  }
}

.alert-msg-show {
  right: 24px;
  top: 40px;
  z-index: 1005;
}

// Emoji Picker

.pickerEmoji{
  position: absolute;
  bottom: -50px;
  left: 10%;
  transform: translateX(-50%);
}

// Toast
.Toastify {
  .Toastify__toast{
    min-height: 43px;
  }
  .Toastify__close-button {
    top: 6px;
  }
  .Toastify__close-button--light {
    color: $light;
  }
}

// Tranfer List

.rdl-filter, .rdl-control{
  background-color: $input-bg;
  border: 1px solid $input-border-color !important;
  color: $input-color !important;
}

.rdl-move{
  background: var(--#{$prefix}light) !important;
  border: 1px solid var(--#{$prefix}light) !important;
  color: var(--#{$prefix}body-color) !important;
}

.react-dual-listbox {
  input:disabled, select:disabled{
    background-color: $input-disabled-bg !important;
    border: 1px solid $input-border-color !important;
  }
}

.css-26l3qy-menu{
  background-color: var(--#{$prefix}gray-200) !important;
  border-color: var(--#{$prefix}border-color) !important;
}

.css-1n7v3ny-option{
  background-color: var(--#{$prefix}gray-300) !important;
  border-color: var(--#{$prefix}border-color) !important;
}


.cardheadercustom{
  padding: 5px 25px !important;
}

.list-group-item-custom{
  padding-top: 30px;
  background-color: red !important;
}

.card-upcomming-event:hover{
  color: #ff7a59;
  border: #ff7a59 solid 1px;
  background:#fff;
  cursor: pointer;
}




.h-800{
  height: 80vh;
}

.w-800{
  height: 80vh;
}

.h-80{
  width: 80vh;
}

/* Media query para pantallas más pequeñas (hasta 767px) */
@media only screen and (max-width: 767px) {
  .h-80{
    height: 80vh;
  }
  .w-80{
    width: 80vh;
  }
}

/* Media query para pantallas medianas (768px a 991px) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h-80{
    height: 40vh;
  }
  .w-80{
    width: 40vh;
  }
}

/* Media query para pantallas grandes (992px a 1199px) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h-80{
    height: 26vh;
  }
  .w-80{
    width: 26vh;
  }
}

/* Media query para pantallas extra grandes (más de 1200px) */
@media only screen and (min-width: 1200px) {
  .h-80{
    height: 20vh;
  }
  .w-80{
    width: 20vh;
  }
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.inmob-img-parent{
  width: 100%;
  overflow: hidden !important;
  display: flex;
}

.inmob-img{
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  width: inherit;
  object-fit: cover;  
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

.custom-footer{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
}

.r-icon{
  font-size: 1.5rem;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerdetails{
  width: 100%;
  justify-content: end;
  .busqueda-footer-item{
    line-height: 1rem;
    margin: 0 5px;
    color: #ff7a59;
    font-size: 1rem;
  }

  i{
    font-size: 1rem;
  }
}

li{
  list-style-type: none;
}



.img-container-sm {
  width: 100%;  /* Puedes ajustar este valor según tus necesidades */
  height: 80px; /* Altura fija, ajusta según tus preferencias */
  overflow: hidden;
  position: relative;
}

.img-container-sm img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.acitivity-timeline{
  padding-bottom: 120px !important;
}

.avatar-container-sm {
  width: 3rem; /* Ancho del contenedor */
  height: 3rem; /* Alto del contenedor */
  border-radius: 50%; /* Hace que el contenedor sea circular */
  overflow: hidden; /* Esconde cualquier parte de la imagen que sobresalga del contenedor */
  background-size: cover; /* La imagen cubre todo el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-color: #f0f0f0; /* Color de fondo de respaldo */
}


.avatar-container-md {
  width: 5rem; /* Ancho del contenedor */
  height: 5rem; /* Alto del contenedor */
  border-radius: 4px; /* Radio de borde para esquinas redondeadas */
  overflow: hidden; /* Esconde cualquier parte de la imagen que sobresalga del contenedor */
  background-color: #f0f0f0; /* Color de fondo de respaldo */
  display: flex; /* Usa flexbox para centrar la imagen */
  justify-content: center; /* Centra la imagen horizontalmente */
  align-items: center; /* Centra la imagen verticalmente */
}

.avatar-container-md img {
  width: 100%; /* Asegura que la imagen ocupe todo el ancho del contenedor */
  height: 100%; /* Asegura que la imagen ocupe todo el alto del contenedor */
  object-fit: cover; /* Escala la imagen para cubrir todo el contenedor sin distorsión */
}



/* KanbanColumns.css */
.kanban-columns-container {
  display: flex;
  overflow-x: auto;
  scrollbar-color: #888 #ccc;
  scrollbar-width: thin;

  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra interna suave */
  border-radius: 8px; /* Opcional: bordes redondeados para el tablero */
  background-color: #f7f8fa; /* Opcional: un fondo suave para destacar el tablero */
  padding: 7px;
}

.kanban-columns-container::-webkit-scrollbar {
  height: 10px;
}

.kanban-columns-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.kanban-columns-container::-webkit-scrollbar-track {
  background-color: #ccc;
}

.kanban-column {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  margin-right: 15px;
  background-color: #f8f9fa; /* Color de fondo opcional para las columnas */
  border-radius: 5px;
  overflow: hidden;
}

.kanban-column-header {
  padding: 10px;
  color: white;
  text-align: center;
}

.kanban-column-content {
  overflow-y: auto;
  height: 600px; /* Ajusta esta altura según tus necesidades */
  padding: 10px;
  flex-grow: 1;
}

/* Estilo de scrollbar personalizado para el contenido */
.kanban-column-content::-webkit-scrollbar {
  width: 8px;
}

.kanban-column-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.kanban-column-content::-webkit-scrollbar-track {
  background-color: #ccc;
}

