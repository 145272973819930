/* Por defecto, ocultamos el contenido móvil */
.mobile-only {
    display: none;
  }
  
  /* En dispositivos móviles (ancho máximo de 767px) */
  @media (max-width: 767px) {
    /* Ocultamos el contenido de escritorio */
    .desktop-only {
      display: none !important;
    }
    /* Mostramos el contenido móvil */
    .mobile-only {
      display: block !important;
    }
  }
  