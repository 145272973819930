/* Styles for SelectDropdownCustom component */
.custom-dropdown-custom .dropdown-item{
    padding: 0px;
}

.custom-dropdown-custom .list-group-item{
    padding: 0px !important;
    border: none;
}

.custom-dropdown-custom .dropdown-header{
    padding: 0px !important;
}

.custom-badge:hover{
    background-color: rgba(241, 125, 52, 1);
    color: #fff !important;
}

.custom-badge,.text-elipsis {
    white-space: nowrap;      /* Evita que el texto se rompa en varias líneas */
    overflow: hidden;        /* Oculta cualquier texto que desborde el contenedor */
    text-overflow: ellipsis; /* Agrega los puntos suspensivos (...) al final del texto */
    max-width: 100%;         /* Evita que el badge se ensanche más allá de su contenedor */
    display: inline-block;   /* Hace que el badge se comporte como un elemento en línea */
}

.dropdown-menu.show{
    transform: translate(0px, 26px) !important;
    /* background-color: rgba(241, 125, 52, 0); */
}

/* .listgroupitem_{
    background-color: rgba(241, 125, 52, .5);
} */