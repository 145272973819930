/* Styles for TablaCrud component */
.heigh400{
    min-height: 300px;   
}

.selected-row {
    background-color: rgba(0, 123, 255, 0.1);
  }
  
  .selected-row:hover {
    background-color: rgba(0, 123, 255, 0.2);
  }